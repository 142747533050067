import { BASE_ASSETS_URL } from "../utils/constants"

const ServicesPage = ({ settings, text }) => {
    let dolomitiMobileCardUrl
    switch(settings.language) {
        case "it-IT":
            dolomitiMobileCardUrl = "https://www.dolomiticard-villnoess.com/it.html"
            break
        case "en-US":
            dolomitiMobileCardUrl = "https://www.dolomiticard-villnoess.com/en.html"
            break
        default:
            dolomitiMobileCardUrl = "https://www.dolomiticard-villnoess.com/"
            break
    }

    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/garden_1.jpg)` }}></div>
            <div className="container">
                <h1>{ text.servicesTitle }</h1>
                <p>{ text.servicesBody }</p>
                <ul className="list-group">
                    <li className="list-group-item">{ text.servicesDolomitiMobilCard } <a className="link-offset-2 link-underline link-underline-opacity-0" target="_blank" href={ dolomitiMobileCardUrl } rel="noreferrer">DolomitiMobil Card</a>.</li>
                    <li className="list-group-item">{ text.servicesWorkWithFamilyMessner }<br /><span className="fw-lighter">{ text.servicesWorkWithFamilyMessnerDetails }</span></li>
                    <li className="list-group-item">{ text.servicesVegetableGarden }</li>
                    <li className="list-group-item">{ text.servicesBooks }</li>
                    <li className="list-group-item">{ text.servicesBoardgames }</li>
                    <li className="list-group-item">{ text.servicesEggs }</li>
                    <li className="list-group-item">{ text.servicesGrill }</li>
                    <li className="list-group-item">{ text.servicesDeckchairs }</li>
                    <li className="list-group-item">{ text.servicesWashingmashines }</li>
                    <li className="list-group-item">{ text.servicesTowels }</li>
                </ul>
            </div>
        </div>
    )
}

export default ServicesPage
