import BlogPage from "../components/BlogPage"
import { BASE_ASSETS_URL } from "../utils/constants"

const FamilyPage = ({ text }) => {
    const blogPosts = [{
        key: "family-parents",
        title: text.familyValeriaGuentherTitle,
        subtitle: null,
        body: text.familyValeriaGuentherBody,
        images: [
            {
                url: `${BASE_ASSETS_URL}images/family_valeria_guenther_6.png`,
                size: "sm",
                alt: "Valeria and Günther. Günther is holding a black sheep."
            }
        ]
    },
    {
        key: "family-children",
        title: text.familyPhilippJulianDavidTitle,
        subtitle: null,
        body: text.familyPhilippJulianDavidBody,
        images: [
            {
                url: `${BASE_ASSETS_URL}images/family_boys_1.jpg`,
                size: "md",
                alt: "Julian, David, and Philipp sitting on a wooden table in the garden."
            }
        ]
    }]

    //TODO: trim image of family
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/family_1.jpg)` }}></div>
            <div className="container">
                <h1>{ text.familyTitle }</h1>
                <BlogPage text={ text } blogPosts={ blogPosts } />
            </div>
        </div>
    )
}

export default FamilyPage
