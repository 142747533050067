import { useEffect, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { localization } from "./utils/text"
import HomePage from "./routes/HomePage"
import ErrorPage from "./routes/ErrorPage"
import RouterRoot from "./routes/RouterRoot"
import FamilyPage from "./routes/FamilyPage"
import HistoryPage from "./routes/HistoryPage"
import AnimalsPage from "./routes/AnimalsPage"
import EnvironmentPage from "./routes/EnvironmentPage"
import ApartmentsPage from "./routes/ApartmentsPage"
import PricesPage from "./routes/PricesPage"
import ServicesPage from "./routes/ServicesPage"
import SheepPage from "./routes/SheepPage"
import ArrivalPage from "./routes/ArrivalPage"
import ContactPage from "./routes/ContactPage"
import BookRoomPage from "./routes/BookRoomPage"
import PrivacyPage from "./routes/PrivacyPage"
import LegalInformationPage from "./routes/LegalInformationPage"

const initialSettings = {
    language: "de-DE",
    dataBsTheme: "light",
    cookiesAccepted: false,
    acceptOnlyNecessaryCookies: true,
}

const App = () => {
    const [settings, setSettings] = useState(initialSettings)
    const [text, setText] = useState(localization(settings.language))
    
    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage
        let lang = "en-US"
        if (userLanguage) {
            switch(userLanguage.toLowerCase()) {
                case "it":
                case "it-it":
                    lang = "it-IT"
                    break
                case "de":
                case "de-at":
                case "de-de":
                case "de-ch":
                    lang = "de-DE"
                    break
                default:
                    lang = "en-US"
                    break
            }
            setSettings({
                ...settings,
                language: lang,
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Handle Website Theme
        const element = document.getElementsByTagName('html')
        if (element.length > 0) {
            element[0].setAttribute('data-bs-theme', settings.dataBsTheme)
        }

        // Handle Website Language
        localization(settings.language).then((texts) => setText(texts))
    }, [ settings ])

    const router = createBrowserRouter([
        {
            path: "/",
            element: <RouterRoot text={ text } settings={ settings } setSettings={ setSettings } />,
            errorElement: <ErrorPage />,
            children: [
                { path: "/", element: <HomePage text={ text } />, },
                { path: "/family", element: <FamilyPage text={ text } />, },
                { path: "/history", element: <HistoryPage text={ text } />, },
                { path: "/animals", element: <AnimalsPage text={ text } />, },
                { path: "/environment", element: <EnvironmentPage text={ text } settings={ settings } />, },
                { path: "/apartments", element: <ApartmentsPage text={ text } />, },
                { path: "/prices", element: <PricesPage text={ text } />, },
                { path: "/services", element: <ServicesPage settings={ settings } text={ text } />, },
                { path: "/sheep", element: <SheepPage text={ text } />, },
                { path: "/arrival", element: <ArrivalPage text={ text } settings={ settings } />, },
                { path: "/contact", element: <ContactPage text={ text } />, },
                { path: "/bookroom", element: <BookRoomPage text={ text } settings={ settings } />, },
                { path: "/privacy", element: <PrivacyPage text={ text } />, },
                { path: "/legalinfo", element: <LegalInformationPage text={ text } />, },
            ],
        },
    ])

    return (
        <RouterProvider router={ router } />
    )
}

export default App
