import { IconBed, IconToolsKitchen2, IconBath, IconWindow, IconUser, IconStack2, IconBedFlat } from "@tabler/icons-react"

const ApartmentEntryCarouselItem = ({ imageSrc, active = false }) => {
    return (
        <div className={ active ? "carousel-item active" : "carousel-item"}>
            <div className="card text-bg-dark">
                <img src={ imageSrc } className="d-block w-100 img-fluid rounded-start" alt="..." />
            </div>
        </div>
    )
}
const ApartmentEntry = ({ apartmentText }) => {
    const apartmentCarouselId = apartmentText.apartmentName.replace(/\s/g, "")

    return (
        <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-4">
                    <div id={ `${apartmentCarouselId}` } className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            { apartmentText.apartmentImageSrc.map((imageUrl, index) =>
                                <ApartmentEntryCarouselItem key={ imageUrl } imageSrc={ imageUrl } active={ index === 0 } />
                            )}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target={ `#${apartmentCarouselId}` } data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target={ `#${apartmentCarouselId}` } data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">{ apartmentText.apartmentName }</h5>
                        <div className="card-text">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                                <div className="col text-right">
                                    <IconBed className="mx-3" />{ apartmentText.apartmentBedroom }
                                </div>
                                <div className="col">
                                    <IconToolsKitchen2 className="mx-3" />{ apartmentText.apartmentKitchen }
                                </div>
                                <div className="col">
                                    <IconBath className="mx-3" />{ apartmentText.apartmentBathroom }
                                </div>
                                <div className="col">
                                    <IconWindow className="mx-3" />{ apartmentText.apartmentView }
                                </div>
                                <div className="col">
                                    <IconUser className="mx-3" />{ apartmentText.apartmentPeople }
                                </div>
                                <div className="col">
                                    <IconStack2 className="mx-3" />{ apartmentText.apartmentEquipment }
                                </div>
                                <div className="col">
                                    <IconBedFlat className="mx-3" />{ apartmentText.apartmentAdditionalBed }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApartmentEntry
