import { BASE_API_URL } from "./constants"

export const localization = async (language) => {
    const response = await fetch(BASE_API_URL + 'texts/' + language)
    const apiTexts = await response.json()
    const formattedTexts = {}

    apiTexts.forEach((apiText) => {
        formattedTexts[apiText.textId] = apiText.text
    })
    
    return formattedTexts
}
