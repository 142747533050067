const LegalInformationPage = ({ text }) => {
    return (
        <div className="drocker-body drocker-no-image">
            <div className="container">
                <h1>{ text.legalInformationTitle }</h1>
                <h3>{ text.legalInformationSubtitle }</h3>
                <p dangerouslySetInnerHTML={{ __html: text.legalInformationBody }}></p>
            </div>
        </div>
    )
}

export default LegalInformationPage
