import BlogPage from "../components/BlogPage"
import { BASE_ASSETS_URL } from "../utils/constants"

const SheepPage = ({ text }) => {
    const blogPosts = [{
        key: "villnoesser-brillenschaf-intro",
        title: text.villnoesserBrillenschafTitle,
        subtitle: text.villnoesserBrillenschafSubtitle,
        body: text.villnoesserBrillenschafBody,
        images: [
            {
                url: `${BASE_ASSETS_URL}images/sheep_guenther_2.jpg`,
                size: "lg",
                alt: "Günther Messner feeding sheep"
            },
            {
                url: `${BASE_ASSETS_URL}images/sheep_grass_1.jpg`,
                size: "lg",
                alt: "Sheep on meadow"
            }
        ]
    }]

    //TODO: trim image of sheep
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/sheep_garden_1.jpg)` }}></div>
            <div className="container">
                <h1>{ text.sheepTitle }</h1>
                <BlogPage text={ text } blogPosts={ blogPosts } />
            </div>
        </div>
    )
}

export default SheepPage
