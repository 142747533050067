import { BASE_API_URL } from "./constants"

export const getPrices = async () => {
    const response = await fetch(BASE_API_URL + 'prices/')
    const apiPrices = await response.json()
    return apiPrices
}

export const getSurcharges = async () => {
    const response = await fetch(BASE_API_URL + 'surcharges/')
    const apiSurcharges = await response.json()
    return apiSurcharges
}

export const formatCurrency = (inputNumber) => {
    return `${inputNumber.toFixed(2).replace('.', ',')} €`
}
