import { BASE_ASSETS_URL } from "../utils/constants"

const ArrivalPage = ({ text, settings }) => {
    // TODO: trim image for entrance
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/drockerhof_entrance_2.jpg)` }}></div>
            <div className="container">
                <h1>{ text.arrivalTitle }</h1>
                <h3>{ text.arrivalSubtitle }</h3>
                <ul className="list-group list-group-flush list-group-numbered mb-3">
                    <li className="list-group-item">{ text.arrivalDirections1 }</li>
                    <li className="list-group-item">{ text.arrivalDirections2 }</li>
                    <li className="list-group-item">{ text.arrivalDirections3 }</li>
                    <li className="list-group-item">{ text.arrivalDirections4 }</li>
                    <li className="list-group-item">{ text.arrivalDirections5 }</li>
                    <li className="list-group-item">{ text.arrivalDirections6 }</li>
                    <li className="list-group-item">{ text.arrivalDirections7 }</li>
                </ul>
                { (settings.acceptOnlyNecessaryCookies === false) && 
                    <>
                        <h5>{ text.arrivalFindUsOnGoogleMaps }</h5>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d175809.52695780652!2d11.316188838653034!3d46.48783999401388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47780e2ddbee4f7f%3A0xa142d339770b0584!2sDrockerhof!5e0!3m2!1sde!2sit!4v1708891723718!5m2!1sde!2sit" style={{ border: 0, width: "100%", maxHeight: "600px", minHeight: "400px" }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="drocker-google-maps" />
                    </>
                }
            </div>
        </div>
    )
}

export default ArrivalPage
