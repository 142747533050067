import { useEffect, useState } from "react"
import { Link, useLocation, Navigate } from "react-router-dom"
import { IconMail, IconMoonFilled, IconPhone, IconSunFilled } from "@tabler/icons-react"

const NavigateWrapper = ({ setBookRoom }) => {
    setBookRoom(false)
    return (<Navigate to="bookroom" />)
}

const Navigation = ({ text, settings, setSettings }) => {
    const [bookRoom, setBookRoom] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)
    const Logo = text.navLogo ? require(`../assets/${text.navLogo}.png`) : null
    
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            // Check if the user has scrolled past the navigation bar
            if (scrollTop > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    
    const toggleTheme = (e) => {
        e.preventDefault()
        setSettings({
            ...settings,
            dataBsTheme: settings.dataBsTheme === "light" ? "dark" : "light",
        })
    }

    const BookRoomOnClick = (e) => {
        e.preventDefault()
        setBookRoom(true)
    }

    const setLanguage = (e, lang) => {
        e.preventDefault()
        setSettings({
            ...settings,
            language: lang,
        })
    }

    const location = useLocation()
    useEffect(() => {
        document.querySelector('.navbar-collapse')?.classList?.remove('show')
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optionally, you can use 'auto' for instant scrolling
        })
    }, [ location ])

    return (
        <div>
            <div className="container">
                <div className="row pt-2">
                    <div className="col text-center">
                        <a className="link-underline link-underline-opacity-0" href="tel:+390472840030" target="_blank" rel="noreferrer">
                            <IconPhone />+39 0472 840 030
                        </a>
                    </div>
                    <div className="col text-center">
                        <a className="link-underline link-underline-opacity-0" href="mailto:info@drockerhof.it" target="_blank" rel="noreferrer">
                            <IconMail />info@drockerhof.it
                        </a>
                    </div>
                </div>
            </div>
            <div className={ `container drocker-navigation ${isScrolled ? 'drocker-scrolled' : ''}` }>
                { bookRoom && <NavigateWrapper setBookRoom={ setBookRoom } /> }
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container container-fluid">
                        <Link to="/">
                            <img src={ Logo } alt="Logo" className="d-inline-block align-text-top rounded-circle drocker-logo"/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item dropdown drocker-navigation-item">
                                    <a className="nav-link dropdown-toggle mx-lg-2" href="/" id="navbarHomeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    { text.navHomepage }
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarHomeDropdown">
                                        <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="family">{ text.navHomepageTheFamily }</Link></li>
                                        <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="history">{ text.navHomepageHistory }</Link></li>
                                        { /* <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="animals">{ text.navHomepageAnimals }</Link></li> */ }
                                        <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="environment">{ text.navHomepageEnvironment }</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown drocker-navigation-item">
                                    <a className="nav-link dropdown-toggle mx-lg-2" href="apartments" id="navbarApartmentsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    { text.navApartments }
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarApartmentsDropdown">
                                        <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="apartments">{ text.navApartmentsApartments }</Link></li>
                                        <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="prices">{ text.navApartmentsPrices }</Link></li>
                                        <li><Link className="dropdown-item drocker-navigation-dropdown-item" to="services">{ text.navApartmentsServices }</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item drocker-navigation-item">
                                    <Link className="nav-link mx-lg-2" aria-current="page" to="sheep">{ text.navSheep }</Link>
                                </li>
                                <li className="nav-item drocker-navigation-item">
                                    <Link className="nav-link mx-lg-2" aria-current="page" to="arrival">{ text.navArrival }</Link>
                                </li>
                                <li className="nav-item drocker-navigation-item">
                                    <Link className="nav-link mx-lg-2" aria-current="page" to="contact">{ text.navContact }</Link>
                                </li>
                                <form className="container-fluid justify-content-start mx-lg-2 drocker-navigation-item">
                                    <button className="btn btn-outline-success me-2 mt-1 drocker-navigation-button" type="button" onClick={ BookRoomOnClick }>{ text.navBookRoom }</button>
                                </form>
                                <li className="nav-item drocker-navigation-item">
                                    <a className="nav-link" aria-current="page" href={ location.pathname } onClick={ (e) => setLanguage(e, "de-DE")} >DE</a>
                                </li>
                                <li className="nav-item drocker-navigation-item">
                                    <a className="nav-link" aria-current="page" href={ location.pathname } onClick={ (e) => setLanguage(e, "it-IT")}>IT</a>
                                </li>
                                <li className="nav-item drocker-navigation-item">
                                    <a className="nav-link" aria-current="page" href={ location.pathname } onClick={ (e) => setLanguage(e, "en-US")}>EN</a>
                                </li>
                                { null && <form className="container-fluid justify-content-start">
                                    <button className="btn btn-outline-success me-2" type="button" onClick={ toggleTheme }>{ settings.dataBsTheme === "light" ? <IconMoonFilled /> : <IconSunFilled /> }</button>
                                </form> }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navigation
