import { useState } from "react"
import { Link } from "react-router-dom"

const CookieBanner = ({ text, settings, setSettings }) => {
    const [accepted, setAccepted] = useState(false)

    const acceptAllCookies = () => {
        setAccepted(true)
        setSettings({
            ...settings,
            cookiesAccepted: true,
            acceptOnlyNecessaryCookies: false,
        })
    }

    const acceptNecessaryCookies = () => {
        setAccepted(true)
        setSettings({
            ...settings,
            cookiesAccepted: true,
            acceptOnlyNecessaryCookies: true,
        })
    }

    if (accepted) {
        return null // If cookies are accepted, hide the banner
    }

    return (
        <div className="cookie-banner fixed-bottom bg-dark text-light p-3">
            <div className="container d-flex justify-content-between align-items-center">
                <div className="col-8">
                    <p>
                        { text.cookieBannerExplanation } <Link to="privacy">{ text.footerPrivacy }</Link>.
                    </p>
                </div>
                <div className="col-4 d-flex justify-content-evenly flex-column">
                    <button className="btn btn-outline-success w-100 mb-2" onClick={ acceptAllCookies }>{ text.cookieBannerAcceptAll }</button>
                    <button className="btn btn-outline-secondary w-100" onClick={ acceptNecessaryCookies }>{ text.cookieBannerAcceptOnlyNecessary }</button>
                </div>
            </div>
        </div>
    )
}

export default CookieBanner
