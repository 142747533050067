import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { sendMail } from "../utils/mail"
import 'dayjs/locale/en-gb'; // English locale
import 'dayjs/locale/it'; // Italian locale
import 'dayjs/locale/de'; // German locale

const BookRoomPage = ({ text, settings }) => {
    const [ formData, setFormData ] = useState({
        name: '',
        surname: '',
        email: '',
        phoneNo: '',
        arrival: dayjs().add(1,'month'),
        departure: dayjs().add(1,'month').add(7,'day'),
        noOfAdults: 0,
        noOfChildren: 0,
        noOfPets: 0,
        message: ''
    })
    const [notificationMessage, setNotificationMessage] = useState(null)
    const [formLocale, setFormLocale] = useState('en-gb')
    const [sendingBlocked, setSendingBlocked] = useState(false)

    useEffect(() => {
        switch(settings.language) {
            case 'de-DE': {
                dayjs.locale('de')
                setFormLocale('de')
                break
            }
            case 'it-IT': {
                dayjs.locale('it')
                setFormLocale('it')
                break
            }
            default: {
                dayjs.locale('en-gb')
                setFormLocale('en-gb')
                break
            }
        }
        setFormData({
            ...formData,
            arrival: dayjs(formData.arrival),
            departure: dayjs(formData.departure),
        })
    }, [ formData, settings ])

    const onFormSubmit = (e) => {
        e.preventDefault()
        if ((formData.name === '') || (formData.surname === '')) {
            throwError(text.bookRoomErrorMissingName)
            return
        }
        if (formData.email === '') {
            throwError(text.bookRoomErrorMissingEmail)
            return
        }
        if ((formData.arrival === '') || (formData.departure === '')) {
            throwError(text.bookRoomErrorMissingDates)
            return
        }
        if (formData.noOfAdults === 0) {
            throwError(text.bookRoomErrorMissingPeople)
            return
        }
        if ((formData.noOfChildren > 0) && (formData.message === '')) {
            throwError(text.bookRoomErrorMissingChildrenMessage)
            return
        }

        setSendingBlocked(true)
        clearMessage()
        const formattedFormData = {
            ...formData,
            arrival: formData.arrival.locale('de').format('dddd, DD.MM.YYYY'),
            departure: formData.departure.locale('de').format('dddd, DD.MM.YYYY')
        }
        sendMail(formattedFormData).then((mailSentSuccessfully) => {
            setSendingBlocked(false)
            if (!mailSentSuccessfully)
                throwError(text.bookRoomErrorSendingMail)
            else
                showMessage(text.bookRoomSuccessSendingMail)
        })
    }

    const throwError = (errorMessage) => {
        setNotificationMessage({
            type:'error',
            text: errorMessage,
            className: 'alert-danger'
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optionally, you can use 'auto' for instant scrolling
        })
    }

    const showMessage = (notificationMessage) => {
        setNotificationMessage({
            type:'message',
            text: notificationMessage,
            className: 'alert-success'
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optionally, you can use 'auto' for instant scrolling
        })
    }

    const clearMessage = () => {
        setNotificationMessage(null)
    }

    const onTextInputChange = (e, formAttribute) => {
        const formDataCopy = { ...formData }
        formDataCopy[formAttribute] = e.target.value
        setFormData(formDataCopy)
    }

    const onNumberInputChange = (e, formAttribute) => {
        const formDataCopy = { ...formData }
        let value = parseInt(e.target.value) || 0
        if (value < 0)
            value = 0
        if (value > 6)
            value = 6
        formDataCopy[formAttribute] = value
        setFormData(formDataCopy)
    }

    const onIncrementDecrementClick = (e, targetElementId, delta) => {
        const inputElement = document.getElementById(targetElementId)
        if (inputElement) {
            let value = parseInt(inputElement.value) || 0
            value += delta
            if (value < 0)
                value = 0
            if (value > 6)
                value = 6
            inputElement.value = value
            switch(targetElementId) {
                case "formNoOfAdults":
                    if (formData.noOfAdults !== value)
                        setFormData({
                            ...formData,
                            noOfAdults: value
                        })
                    break
                case "formNoOfChildren":
                    if (formData.noOfChildren !== value)
                        setFormData({
                            ...formData,
                            noOfChildren: value
                        })
                    break
                case "formNoOfPets":
                    if (formData.noOfPets !== value)
                        setFormData({
                            ...formData,
                            noOfPets: value
                        })
                    break
                default:
                    break
            }
        } else {
            console.error(`Element with id '${targetElementId}' not found.`)
        }
    }

    return (
        <div className="drocker-body drocker-no-image">
            <div className="container">
                <h1>{ text.bookRoomTitle }</h1>
                <p>{ text.bookRoomBody }</p>
                { (notificationMessage !== null) && (
                    <div className={ `alert ${notificationMessage.className} d-flex justify-content-between align-items-center` } role="alert">
                        { notificationMessage.text }
                        <button type="button" className="btn-close" aria-label="Close" onClick={ clearMessage }></button>
                    </div>
                )}
                <form>
                    <div className="mb-3 form-floating">
                        <input name="name" type="text" className="form-control" id="formGroupNameInput" placeholder="" value={ formData.name } onChange={ (e) => onTextInputChange(e, 'name') } required />
                        <label htmlFor="formGroupNameInput" className="form-label">{ text.bookRoomName }</label>
                    </div>
                    <div className="mb-3 form-floating">
                        <input name="surname" type="text" className="form-control" id="formGroupSurnameInput" placeholder="" value={ formData.surname } onChange={ (e) => onTextInputChange(e, 'surname') } required />
                        <label htmlFor="formGroupSurnameInput" className="form-label">{ text.bookRoomSurname }</label>
                    </div>
                    <div className="mb-3 form-floating">
                        <input name="email" type="email" className="form-control" id="formGroupEmailInput" placeholder="example@email.com" value={ formData.email } onChange={ (e) => onTextInputChange(e, 'email') } required />
                        <label htmlFor="formGroupEmailInput" className="form-label">{ text.bookRoomEmail }</label>
                    </div>
                    <div className="mb-3 form-floating">
                        <input name="phoneNo" type="tel" className="form-control" id="formGroupPhoneInput" placeholder="(+39) 1234 567890" value={ formData.phoneNo } onChange={ (e) => onTextInputChange(e, 'phone') } />
                        <label htmlFor="formGroupPhoneInput" className="form-label">{ text.bookRoomPhone }</label>
                    </div>
                    <div className="input-group mb-3 row">
                        <div className="col form-floating">
                            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale={ formLocale }>
                                <DatePicker label={ text.bookRoomArrival } value={ formData.arrival } onChange={ (newArriv) => setFormData({ ...formData, arrival: newArriv, }) } />
                            </LocalizationProvider>
                        </div>
                        <div className="col form-floating">
                            <LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale={ formLocale }>
                                <DatePicker label={ text.bookRoomDeparture } value={ formData.departure } onChange={ (newDept) => setFormData({ ...formData, departure: newDept, }) } />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="row ">
                        <label className="form-label">{ text.bookRoomGuests }</label>
                        <div className="col input-group mb-3">
                            <button className="btn btn-outline-primary" type="button" onClick={ (e) => onIncrementDecrementClick(e, 'formNoOfAdults', (-1)) }>-</button>
                            <div className="form-floating">
                                <input name="noOfAdults" type="text" className="form-control" id="formNoOfAdults" value={ formData.noOfAdults } aria-label={ `${text.bookRoomNumberOf} ${text.bookRoomAdults}` } aria-describedby="formGroupNoOfAdults" min={ 0 } max={ 6 } onChange={ (e) => onNumberInputChange(e, 'numberOfAdults') } />
                                <label htmlFor="formGroupNoOfAdults">{ text.bookRoomAdults }</label>
                            </div>
                            <button className="btn btn-outline-primary" type="button" onClick={ (e) => onIncrementDecrementClick(e, 'formNoOfAdults', 1) }>+</button>
                        </div>
                        <div className="col input-group mb-3">
                            <button className="btn btn-outline-primary" type="button" onClick={ (e) => onIncrementDecrementClick(e, 'formNoOfChildren', (-1)) }>-</button>
                            <div className="form-floating">
                                <input name="noOfChildren" type="text" className="form-control" id="formNoOfChildren" value={ formData.noOfChildren } aria-label={ `${text.bookRoomNumberOf} ${text.bookRoomChildren}` } aria-describedby="formGroupNoOfChildren" min={ 0 } max={ 6 } onChange={ (e) => onNumberInputChange(e, 'numberOfChildren') } />
                                <label htmlFor="formGroupNoOfChildren">{ text.bookRoomChildren }</label>
                            </div>
                            <button className="btn btn-outline-primary" type="button" onClick={ (e) => onIncrementDecrementClick(e, 'formNoOfChildren', 1) }>+</button>
                        </div>
                        <div className="col input-group mb-3">
                            <button className="btn btn-outline-primary" type="button" onClick={ (e) => onIncrementDecrementClick(e, 'formNoOfPets', (-1)) }>-</button>
                            <div className="form-floating">
                                <input name="noOfPets" type="text" className="form-control" id="formNoOfPets" value={ formData.noOfPets } aria-label={ `${text.bookRoomNumberOf} ${text.bookRoomPets}` } aria-describedby="formGroupNoOfPets" min={ 0 } max={ 6 } onChange={ (e) => onNumberInputChange(e, 'numberOfPets') } />
                                <label htmlFor="formGroupNoOfPets">{ text.bookRoomPets }</label>
                            </div>
                            <button className="btn btn-outline-primary" type="button" onClick={ (e) => onIncrementDecrementClick(e, 'formNoOfPets', 1) }>+</button>
                        </div>
                    </div>
                    <p className="fw-lighter">
                        { text.bookRoomMessageInfo }
                    </p>
                    <div className="form-floating mb-3">
                        <textarea name="message" className="form-control" placeholder={ `${text.bookRoomContactUs}` } value={ formData.message } id="floatingTextarea" style={{ height: "100px" }} onChange={ (e) => onTextInputChange(e, 'message') } />
                        <label htmlFor="floatingTextarea">{ text.bookRoomMessage }</label>
                    </div>
                    <p className="fw-lighter">
                        <Link to="/privacy">{ text.bookRoomPrivacyLink }</Link> { text.bookRoomPrivacy1 }<br/>
                        { text.bookRoomPrivacy2 }
                    </p>
                    <div className="input-group mb-3 row">
                        <div className="col"></div>
                        <div className="col">
                            <button type="submit" className="btn btn-outline-primary" onClick={ onFormSubmit } disabled={sendingBlocked}>{ text.bookRoomSendRequest }</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BookRoomPage
