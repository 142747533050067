import { formatCurrency } from "../utils/price"

const ApartmentPriceTable = ({ text, apartmentPrices }) => {
    if (!apartmentPrices || !apartmentPrices.david)
        return null

    const getMonthName = (month) => {
        const monthNames = {
            "01": text.january,
            "02": text.february,
            "03": text.march,
            "04": text.april,
            "05": text.may,
            "06": text.june,
            "07": text.july,
            "08": text.august,
            "09": text.september,
            "10": text.october,
            "11": text.november,
            "12": text.december,
        }
        return monthNames[month]
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">{ text.pricesMonth }</th>
                    <th scope="col">David</th>
                    <th scope="col">Julian</th>
                    <th scope="col">Philipp</th>
                </tr>
            </thead>
            <tbody>
                { Object.keys(apartmentPrices.david).sort().map((month) => {
                    const priceDavid = apartmentPrices.david[month]
                    const priceJulian = apartmentPrices.julian[month]
                    const pricePhilipp = apartmentPrices.philipp[month]

                    return (
                        <tr key={ month }>
                            <td>{ getMonthName(month) }</td>
                            <td>{ formatCurrency(priceDavid) }</td>
                            <td>{ formatCurrency(priceJulian) }</td>
                            <td>{ formatCurrency(pricePhilipp) }</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default ApartmentPriceTable
