import { useEffect, useState } from "react"
import ApartmentPriceTable from "../components/ApartmentPriceTable"
import { getPrices, getSurcharges } from "../utils/price"
import SurchargeList from "../components/SurchargeList"

const PricesPage = ({ text }) => {
    const [aptPrices, setAptPrices] = useState([])
    const [aptSurcharges, setAptSurcharges] = useState([])

    useEffect(() => {
        getPrices().then((prices) => setAptPrices(prices))
        getSurcharges().then((surcharges) => setAptSurcharges(surcharges))
    }, [])

    return (
        <div className="drocker-body drocker-no-image">
            <div className="container">
                <h1>{ text.pricesTitle }</h1>
                <p dangerouslySetInnerHTML={{ __html: text.pricesBody }} />
                <p className="fw-lighter" dangerouslySetInnerHTML={{ __html: text.pricesIncludedServices }} />
                <ApartmentPriceTable text={ text } apartmentPrices={ aptPrices } />
                <SurchargeList text={ text } apartmentSurcharges={ aptSurcharges } />
            </div>
        </div>
    )
}

export default PricesPage
