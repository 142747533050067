import BlogPost from "./BlogPost"

const BlogPage = ({ blogPosts }) => {
    return (
        <div className="container container-fluid">
            { blogPosts.map((blogPost) => 
                <BlogPost key={ blogPost.key } title={ blogPost.title } subtitle={ blogPost.subtitle } body={ blogPost.body } images={ blogPost.images } />
            )}
        </div>
    )
}

export default BlogPage
