import { BASE_API_URL } from "./constants"

export const sendMail = async (formData) => {
    const response = await fetch(BASE_API_URL + 'mails/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
    })
    return (response.status >= 200) && (response.status <= 299)
}
