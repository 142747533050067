const PrivacyPage = ({ text }) => {
    return (
        <div className="drocker-body drocker-no-image">
            <div className="container">
                <h1>{ text.privacyTitle }</h1>
                <div dangerouslySetInnerHTML={{ __html: text.privacyBody }}></div>
            </div>
        </div>
    )
}

export default PrivacyPage
