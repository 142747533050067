import ApartmentEntry from "../components/ApartmentEntry"
import { BASE_ASSETS_URL } from "../utils/constants"

const ApartmentsPage = ({ text }) => {
    const apartmentTexts = [{
            key: "apartmentDavid",
            apartmentName: text.apartmentsDavidTitle,
            apartmentDescription: text.apartmentsDavidBody,
            apartmentBedroom: text.apartmentsDavidBedroom,
            apartmentKitchen: text.apartmentsDavidKitchen,
            apartmentBathroom: text.apartmentsDavidBathroom,
            apartmentView: text.apartmentDavidView,
            apartmentPeople: text.apartmentsDavidPeople,
            apartmentEquipment: text.apartmentsDavidEquipment,
            apartmentAdditionalBed: text.apartmentsDavidAdditionalBed,
            apartmentImageSrc: [
                `${BASE_ASSETS_URL}images/appartment_david_1.jpg`,
                `${BASE_ASSETS_URL}images/appartment_david_2.jpg`,
                `${BASE_ASSETS_URL}images/appartment_david_3.jpg`,
            ],
        },
        {
            key: "apartmentJulian",
            apartmentName: text.apartmentsJulianTitle,
            apartmentDescription: text.apartmentsJulianBody,
            apartmentBedroom: text.apartmentsJulianBedroom,
            apartmentKitchen: text.apartmentsJulianKitchen,
            apartmentBathroom: text.apartmentsJulianBathroom,
            apartmentView: text.apartmentJulianView,
            apartmentPeople: text.apartmentsJulianPeople,
            apartmentEquipment: text.apartmentsJulianEquipment,
            apartmentAdditionalBed: text.apartmentsJulianAdditionalBed,
            apartmentImageSrc: [
                `${BASE_ASSETS_URL}images/appartment_julian_1.jpg`,
                `${BASE_ASSETS_URL}images/appartment_julian_2.jpg`,
                `${BASE_ASSETS_URL}images/appartment_julian_3.jpg`,
                //`${BASE_ASSETS_URL}images/appartment_julian_4.jpg`,  // Vertical image, does not fit
            ],
        },
        {
            key: "apartmentPhilipp",
            apartmentName: text.apartmentsPhilippTitle,
            apartmentDescription: text.apartmentsPhilippBody,
            apartmentBedroom: text.apartmentsPhilippBedroom,
            apartmentKitchen: text.apartmentsPhilippKitchen,
            apartmentBathroom: text.apartmentsPhilippBathroom,
            apartmentView: text.apartmentPhilippView,
            apartmentPeople: text.apartmentsPhilippPeople,
            apartmentEquipment: text.apartmentsPhilippEquipment,
            apartmentAdditionalBed: text.apartmentsPhilippAdditionalBed,
            apartmentImageSrc: [
                `${BASE_ASSETS_URL}images/appartment_philipp_1.jpg`,
                `${BASE_ASSETS_URL}images/appartment_philipp_2.jpg`,
                `${BASE_ASSETS_URL}images/appartment_philipp_3.jpg`,
            ],
        }
    ]

    //TODO: trim image of staircase
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/interior_staircase_3.jpg)` }}></div>
            <div className="container">
                <h1>{ text.apartmentsTitle }</h1>
                <p dangerouslySetInnerHTML={{ __html: text.apartmentsBody }}></p>
                { apartmentTexts.map((apartmentText) => 
                    (apartmentText.apartmentName !== undefined) && <ApartmentEntry key={ apartmentText.key } apartmentText={ apartmentText } />
                )}
            </div>
        </div>
    )
}

export default ApartmentsPage
