import { BASE_ASSETS_URL } from "../utils/constants"

const EnvironmentPage = ({ text, settings }) => {
    // TODO: select different image, trim
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/drockerhof_balcony_forestview_1.jpg)` }}></div>
            <div className="container">
                <h1>{ text.environmentTitle }</h1>
                <iframe title="day-trip-map" src={ `${BASE_ASSETS_URL}html/day_trip_map_widget.html#${settings.language}` } className="drocker-map-widget"></iframe>
            </div>
        </div>
    )
}

export default EnvironmentPage
