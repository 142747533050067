import { formatCurrency } from "../utils/price"

const convertAptSurchargesToObject = (aptSurcharges, surchargesObj) => {
    aptSurcharges.forEach((surchargeItem) => {
        const { surcharge, cost } = surchargeItem
        surchargesObj[surcharge] = cost
    })

    return surchargesObj
}

const SurchargeList = ({ text, apartmentSurcharges }) => {
    const surchargesObj = {}
    convertAptSurchargesToObject(apartmentSurcharges, surchargesObj)

    return (
        <div>
            <h2>{ text.surchargesTitle }</h2>
            <p dangerouslySetInnerHTML={{ __html: text.surchargesBody }} />
            <ul className="list-group list-group-flush">
            { (surchargesObj["LOCALTAX"] > 0) ? <li className="list-group-item">{ `${text.surchargesLocalTax}: ${ formatCurrency(surchargesObj["LOCALTAX"]) }` }<br /><span className="fw-lighter">{ text.surchargesLocalTaxExplanation }</span></li> : null }
                { (surchargesObj["ADDITIONALADULT"] > 0) ? <li className="list-group-item">{ `${text.surchargesAddAdult}: ${ formatCurrency(surchargesObj["ADDITIONALADULT"]) }` }</li> : null }
                { (surchargesObj["ADDITIONALCHILDUNDER6"] > 0) ? <li className="list-group-item">{ `${text.surchargesAddChildU6}: ${ formatCurrency(surchargesObj["ADDITIONALCHILDUNDER6"]) }` }</li> : null }
                { (surchargesObj["ADDITIONALCHILD"] > 0) ? <li className="list-group-item">{ `${text.surchargesAddChild}: ${ formatCurrency(surchargesObj["ADDITIONALCHILD"]) }` }</li> : null }
                { (surchargesObj["ADDITIONALPET"] > 0) ? <li className="list-group-item">{ `${text.surchargesAddPet}: ${ formatCurrency(surchargesObj["ADDITIONALPET"]) }` }<br /><span className="fw-lighter">{ text.surchargesAddPetExplanation }</span></li> : null }
                { /* (surchargesObj["LESSTHAN3NIGHTS"] > 0) ? <li className="list-group-item">{ `${text.surchargesAddAdult}: ${ formatCurrency(surchargesObj["LESSTHAN3NIGHTS"]) }` }</li> : null */ }
            </ul>
        </div>
    )
}

export default SurchargeList
