const ContactPage = ({ text }) => {
    return (
        <div className="drocker-body drocker-no-image">
            <div className="container">
                <h1>{ text.contactTitle }</h1>
                <p dangerouslySetInnerHTML={{ __html: text.contactAddress }} />
                <p>
                    { text.contactTelephone }: <a href="tel:+390472840030" target="_blank" rel="noreferrer">+39 0472 840 030</a><br/>
                    WhatsApp: <a href={ `https://wa.me/+393420302036?text=${ text.whatsappMessage }` } target="_blank" rel="noreferrer">+39 342 030 2036</a><br/>
                    Email: <a href="mailto:info@drockerhof.it" target="_blank" rel="noreferrer">info@drockerhof.it</a>
                </p>
            </div>
        </div>
    )
}

export default ContactPage
