import BlogPage from "../components/BlogPage"
import { BASE_ASSETS_URL } from "../utils/constants"

const HistoryPage = ({ text }) => {
    const blogPosts = [{
        key: "history-drockerhof",
        title: text.historyStoryOfTheFarmTitle,
        subtitle: null,
        body: text.historyStoryOfTheFarmBody,
        images: [
            {
                url: `${BASE_ASSETS_URL}images/drockerhof_entrance_1.jpg`,
                size: "lg",
                alt: "Drockerhof main building with Logo on the side"
            },
            {
                url: `${BASE_ASSETS_URL}images/drockerhof_fireplace_2.jpg`,
                size: "lg",
                alt: "Old wooden well in the garden of Drockerhof"
            }
        ]
    },
    {
        key: "certifications-drockerhof",
        title: text.historyFarmCertificationsTitle,
        subtitle: null,
        body: text.historyFarmCertificationsBody,
        images: [
            {
                url: `${BASE_ASSETS_URL}images/logo_organic.png`,
                size: "xs",
                alt: "certified organic logo"
            },
            {
                url: `${BASE_ASSETS_URL}images/logo_slow_food.jpg`,
                size: "xs",
                alt: "presidio slow food logo"
            },
            {
                url: `${BASE_ASSETS_URL}images/appartment_david_1.jpg`,
                size: "lg",
                alt: "Salewa Jacket hanging on a coathanger"
            }
        ]
    }]

    //TODO: trim erbhof image
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/interior_erbhof_certificate_3.jpg)` }}></div>
            <div className="container">
                <h1>{ text.historyTitle }</h1>
                <BlogPage text={ text } blogPosts={ blogPosts } />
            </div>
        </div>
    )
}

export default HistoryPage
