import { Outlet } from "react-router"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import CookieBanner from "../components/CookieBanner"

const RouterRoot = ({ text, settings, setSettings }) => {
    return (
        <div data-bs-theme={ settings.dataBsTheme }>
            <header style={{ zIndex: 1000 }}>
                <Navigation text={ text } settings={ settings } setSettings={ setSettings } />
            </header>
            <CookieBanner text={ text } settings={ settings } setSettings={ setSettings } />
            <Outlet />
            <Footer text={ text } settings={ settings } />
        </div>
    )
}

export default RouterRoot
