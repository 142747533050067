import { useEffect, useRef, useState } from "react"
import BlogPage from "../components/BlogPage"
import { BASE_ASSETS_URL } from "../utils/constants"
//import HomeCarousel from "../components/HomeCarousel"

const HomePage = ({ text }) => {
    const [isMobile, setIsMobile] = useState(false)
    const videoReference = useRef(null)

    useEffect(() => {
        videoReference.current.play()
    }, [])

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768)
        }

        handleResize()
    
        // Event listener for window resize
        window.addEventListener('resize', handleResize)
    
        // Clean up event listener
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
      

    const blogPosts = [{
        key: "home-drockerhof",
        title: text.homeTitle,
        subtitle: text.homeSubtitle,
        body: text.homeBody
    }]

    return (
        <div className="drocker-body">
            <div className={ isMobile ? "drocker-video-container" : ""}>
                <video ref={ videoReference } poster="" /* This is the placeholder until the video loads */className={ isMobile ? "" : "d-block w-100 img-fluid image-element" } autoPlay muted loop playsInline>
                    <source src={ `${BASE_ASSETS_URL}videos/drockerhof_intro.mp4` } type="video/mp4" />
                    <source src={ `${BASE_ASSETS_URL}videos/drockerhof_intro.webm` } type="video/webm" />
                </video>
            </div>
            <div className="container container-fluid">
                <BlogPage text={ text } blogPosts={ blogPosts } />
            </div>
        </div>
    )
}

export default HomePage
