import { BASE_ASSETS_URL } from "../utils/constants"

const AnimalsPage = ({ text }) => {

    //TODO: select better animal image, trim?
    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/pigs_1.jpg)` }}></div>
            <div className="container">
                <h1>Tiere</h1>
            </div>
        </div>
    )
}

export default AnimalsPage
